@import '~react-redux-toastr/src/styles/index.scss';
@import '~react-tippy/dist/tippy.css';
@import 'modules/bootstrap/bootstrap';
@import 'modules/_variables';
@import 'modules/_hamburgers';
@import 'modules/_popover';

body {
  font-family: 'din-2014', sans-serif;
  font-style: normal;
  background-color: #fff;
  color: $text-color;
  font-size: 20px;
  font-weight: 400;
  //overflow-x: hidden;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

ul,
ol,
address {
  margin: 0px;
  padding: 0px;
}
p {
  color: $text-color;
  margin: 0px;
}
a {
  color: $text-color;
  margin: 0px;
  transition: all 150ms linear;
  &:hover,
  &:focus {
    color: $color-purple;
    text-decoration: none;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: industry-inc-base, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.DIN_2014_Regular {
  font-family: 'din-2014', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.DIN_2014_Regular {
  font-family: 'din-2014', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.DIN_2014_Bold_Italic {
  font-family: 'din-2014', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.DIN_2014_Bold {
  font-family: 'din-2014', sans-serif;
  font-weight: 700;
  font-style: normal;
}
.anchor {
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden;
}

@import 'partials/_elements';
@import 'partials/_layout';
@import 'partials/_items';
@import 'partials/_page-404';

@import 'partials/_page-login';
@import 'partials/_page-register';

@import 'modules/_media';
