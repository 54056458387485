@import '~styles/variables.scss';
@import '~styles/mixins.scss';

.reset-password {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;

  &__form {
    width: 100%;
    margin: auto;
    max-width: 500px;
  }

  &__form-title {
    font-size: 3rem;
    margin: 0 auto 10px auto;
    text-align: center;
  }

  &__submit-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__back-wrapper {
    position: absolute;
    bottom: 50px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
  }

  &__back-icon {
    margin: auto;
  }

  @include mdScreen() {
    &__form-title {
      font-size: 4.5rem;
      margin: 0 auto 20px;
    }
  }
}
