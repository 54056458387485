@import '~styles/mixins.scss';

.basic-layout {
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  overflow-y: auto;

  background-image: url('../../images/main_back.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &__header {
    margin: 10px;
    z-index: 100;

    display: flex;
    justify-content: space-between;
  }

  &__content {
    height: calc(100% - 85px);
    width: 100%;
    flex: 1;
    display: flex;
    margin-top: -85px;
    padding: 85px 10px 85px 10px;
  }

  &__right-actions {
    margin: auto 0;
  }

  @include mdScreen() {
    &__content {
      height: calc(100% - 125px);
      margin-top: -125px;
      padding: 125px 40px 125px 40px;
    }

    &__header {
      margin: 40px;
    }
  }
}
