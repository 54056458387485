.actions_wrapper {
  background-color: #fff;
  h4 {
    margin: 0 auto 20px;
    span {
      font-weight: 400;
    }
    &.with_ico {
      margin: 20px auto 40px;
      img,
      svg {
        margin: 0 20px 0 0;
      }
    }
  }
  .sub_header {
    margin: -10px 0 30px;
    max-width: 85%;
  }
  .custom_table {
    &_header {
      background-color: #f8f8f8;
      font-weight: 700;
      padding: 5px 15px;
      margin: 0 auto 30px;
      [class*='col'] {
        &:last-child {
          text-align: right;
        }
      }
    }
    &_item {
      margin: 0 auto 15px;
      [class*='col'] {
        &:first-child {
          padding-left: 30px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translate(-0%, -50%);
            left: 5px;
            height: 7px;
            width: 7px;
            background-color: $color-purple;
            border-radius: 50%;
          }
          &.done {
            &:before {
              background-color: #00a651;
            }
          }
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .custom_listing {
    font-size: 16px;
    &_header {
      background-color: #f8f8f8;
      font-weight: 700;
      padding: 10px 15px;
      align-items: center;
      [class*='col'] {
        &:last-child {
          text-align: right;
        }
      }
    }
    &_item {
      padding: 15px;
      border-bottom: 1px solid #f8f8f8;
      align-items: center;
      transition: all 150ms linear;
      a {
        text-decoration: underline;
      }
      .status {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 5px;
        border-radius: 50%;
        &.red {
          background-color: #c60000;
        }
        &.orange {
          background-color: #fdd751;
        }
        &.green {
          background-color: #29cf61;
        }
      }
      [class*='col'] {
        &:last-child {
          text-align: right;
        }
      }
      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
  .link_edit_ico {
    display: inline-block;
    padding: 0 40px 0 0;
    font-weight: 700;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(-0%, -50%);
      right: 0;
      height: 24px;
      width: 24px;
      // background-image: url('../images/ico_pencil.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .link_edit {
    font-weight: 600;
    color: $color-purple;
    text-decoration: underline;
    display: inline-block;
    margin: 0 0 0 10px;
  }
  .program_status {
    background-image: linear-gradient(to bottom right, #957efc, #ff6cab);
    margin: 0 -30px -30px;
    padding: 20px;
    text-align: center;
    [class*='col-'] {
      position: relative;
      &:after {
        content: '';
        height: 90%;
        width: 1px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translate(-0%, -50%);
        right: 0;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    p {
      color: #fff;
    }
    span {
      color: #fff;
      font-size: 72px;
      font-weight: 700;
    }
  }
  .program_info {
    span {
      &.value {
        font-size: 72px;
        display: block;
        line-height: 1;
        font-weight: 700;
      }
      &.info {
        background-color: $color-purple;
        display: block;
        color: #fff;
        margin: 34px -30px -30px;
        padding: 20px;
        font-size: 11px;
      }
    }
  }
}

.filters_wrapper {
  .input_wrapper {
    margin: 0 auto;
  }
}
