@import '~styles/variables.scss';
@import '~styles/mixins.scss';

.register {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;

  &__form {
    width: 100%;
    margin: auto;
    max-width: 500px;
  }

  &__form {
    &-title {
      font-size: 3rem;
      margin: 0 auto 10px;
      text-align: center;
    }
    &-description {
      max-width: 500px;
      margin: 0 auto 50px;
      text-align: center;
    }
    &-info {
      font-size: 14px;
      text-align: center;
      a {
        font-weight: 700;
      }
    }
  }

  &__submit-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__back-icon {
    margin: auto;
  }

  @include mdScreen() {
    &__form-title {
      font-size: 4.5rem;
      margin: 0 auto 20px;
    }
  }
}
