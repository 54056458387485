@import '~styles/mixins.scss';

.top-bar-nav {
  margin: auto 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  &__logout-button {
    margin: auto 0 auto 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      display: flex;
      margin: auto;
    }
  }

  &__user-email {
    //display: none;
    //max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include mdScreen() {
    &__user-email {
      //display: block;
    }
  }
}
nav {
  padding: 0 22px 40px 22px;
  z-index: 1;
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      margin-left: 25px;
      &:first-child {
        margin: 0px;
      }
      button {
        cursor: pointer;
        border: none;
        background: transparent;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
