@import '~styles/variables.scss';
@import '~styles/mixins.scss';

.login {
  margin: auto;
  width: 100%;

  &__login-form-wrapper {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  &__form {
    width: 100%;
  }

  &__form-title {
    text-align: center;
    font-size: 3rem;
    margin: 0 auto 20px;
  }

  &__reset-password {
    text-align: right;

    a {
      color: $color-purple !important;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &__submit-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__back-wrapper {
    position: absolute;
    bottom: 50px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
  }

  &__back-text {
    margin: auto 0 auto 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.3;

    span {
      margin: 0 5px 0 0;
    }
  }

  &__register-button {
    font-size: 14px !important;
    padding: 5px !important;
    margin: auto;
  }

  @include mdScreen() {
    &__login-form-wrapper {
      padding: 20px;
    }

    &__form-title {
      font-size: 4.5rem;
      text-align: center;
      margin: 0 auto 0;
    }

    &__register-button {
      font-size: 18px !important;
      padding: 10px 30px !important;
      margin: auto;
    }
  }
}
