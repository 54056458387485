/**
 * modules/variables
 * ---
 */
/**
 * Typography
 */

// $font-family-base: 'Work Sans', sans-serif;
// $font-size-base: 18px;
// $line-height-base: 1.6;

/**
 * Colors
 */
$text-color: #4e4188;
$color-purple: #977fff;
