.register-page {
  max-width: 1040px;
  margin: 0 auto;
  padding: 100px 0 50px 0;
  .react-tabs {
    &__tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      margin: 0 auto 100px;
    }
    &__tab {
      position: relative;
      margin: 0 50px;
      padding: 0 0 0 60px;
      cursor: pointer;
      font-family: industry-inc-base, sans-serif;
      span {
        font-family: 'din-2014', sans-serif;
        position: absolute;
        top: 40%;
        transform: translate(-0%, -50%);
        left: 0;
        font-size: 24px;
        color: $color-purple;
        font-weight: 700;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        border: 2px solid $color-purple;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms linear;
      }
      &:focus {
        outline: none;
      }
      &--selected {
        span {
          background-color: $color-purple;
          color: #fff;
        }
      }
    }
    &__tab-panel {
      .react-tabs {
        &__tab-list {
          margin: 75px auto 0;
        }
        &__tab {
          display: none;
        }
      }
    }
  }
  form {
    h3 {
      margin: 0 auto 30px;
    }
    .desc {
      margin: 0 auto 40px;
    }
    .order_package {
      background-color: #fff;
      box-shadow: 0 0 15px 0 rgba(#000, 0.03);
      &-info {
        padding: 30px 30px 0 30px;
        h3 {
          margin: 0px;
        }
      }
      &-type {
        padding: 30px;
        border: solid #f8f8f8;
        border-width: 0 0 1px 0;
      }
      &-summary {
        padding: 15px 40px;
        p {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
        }
        h5 {
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          margin: 15px auto;
          small {
            font-family: 'din-2014', sans-serif;
          }
        }
      }
    }
  }
  .payments_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-type {
      border: 2px solid $color-purple;
      margin: 0 15px 0 0;
      width: 160px;
      min-height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 150ms linear;
      img {
        transition: all 150ms linear;
      }
      &:hover {
        background-color: $color-purple;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  .submit_wrapper {
    text-align: center;
    margin: 75px auto 0;
    small {
      display: block;
      margin: 10px auto 0;
      font-size: 12px;
      a {
        color: $color-purple;
      }
    }
  }
}
