@import './button_mixins';

.button {
  @include button-basic();

  &--kind-standard {
    @include button-standard();

    &.button--red {
      @include button-standard($color-red);
    }

    &.button--gray {
      @include button-standard(gray);
    }
  }
  &--kind-outline {
    @include button-outline();

    &.button--red {
      @include button-outline($color-red);
    }

    &.button--gray {
      @include button-outline(gray);
    }
  }
  &--kind-simple {
    @include button-simple();

    &.button--red {
      @include button-simple($color-red);
    }

    &.button--gray {
      @include button-simple(gray);
    }
  }

  &--kind-link {
    @include button-link();

    &.button--red {
      @include button-link($color-red);
    }

    &.button--gray {
      @include button-link(gray);
    }
  }
}
