@import '~styles/variables.scss';

.page_privacy {
  max-width: 1040px;
  margin: 0 auto;
  padding: 75px 0;
  h1 {
    text-align: center;
    font-size: 30px;
    margin: 75px auto 15px;
  }
  h3 {
    margin: 50px auto 15px;
    font-size: 20px;
    text-align: center;
  }
  p {
    margin: 0 auto 15px;
    font-size: 16px;
    &:last-child {
      margin: 0px;
    }
    a {
      color: $color-purple;
    }
  }
  ul,
  ol {
    margin: 0 auto 15px;
    list-style-type: none;
    li {
      margin: 0 auto 10px;
      padding: 0 0 0 22px;
      position: relative;
      font-size: 16px;
      &:before {
        content: '';
        height: 8px;
        width: 8px;
        background-color: $color-purple;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
    ul,
    ol {
      margin: 0 0 0 30px;
    }
  }
  .doc_wrapper {
    overflow: auto;

    &-inner {
      border: solid $text-color;
      border-width: 0 0 1px 1px;
      width: 1040px;
      .row {
        margin-left: 0px;
        margin-right: 0px;
        [class*='col-'] {
          border: solid $text-color;
          border-width: 1px 1px 0 0;
          padding: 5px 10px;
          font-size: 16px;
          ul {
            margin: 5px -10px -5px -10px;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            li {
              flex: 0 0 50%;
              max-width: 50%;
              margin: 0px;
              padding: 0px;
              border: solid $text-color;
              border-width: 1px 0 0 0;
              padding: 5px 10px;
              &:nth-child(even) {
                border-width: 1px 0 0 1px;
              }
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}
