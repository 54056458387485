@import '~styles/variables.scss';

@mixin button-basic() {
  position: relative;
  background-color: transparent;
  display: inline-block;
  line-height: 1;
  transition: background-color 150ms linear, color 150ms linear;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: 0;
  }

  &:disabled,
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }

  &__loader-wrapper {
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin button-standard($color: $primary-text-color) {
  border: none;
  background-color: $color;
  color: white;
  margin: 0 10px;
  outline: none;
}

@mixin button-outline($color: $color-purple) {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: $color;
  border: 2px solid $color;
  padding: 15px 30px;
  text-transform: uppercase;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: rgba($color, 0.5);
      color: #fff;
    }
    &.fill {
      background-color: $color;
      color: #fff;
    }
  }
}

@mixin button-simple($color: $primary-text-color) {
  @include button-basic();

  font-weight: 600;
  padding: 0;
  color: $color;
  background-color: white;
  transition: all 150ms linear;
  text-decoration: underline;
  margin: 0;
  border: 0;

  .button__loader-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin button-link($color: $color-purple) {
  @include button-basic();

  padding: 0;
  background-color: inherit;
  font-weight: 600;
  color: $color;
  text-decoration: none;
  display: inline-block;
  margin: 0;
  border: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  .button__loader-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
