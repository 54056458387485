//
.btn_custom {
  font-family: industry-inc-base, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: $color-purple;
  border: 2px solid $color-purple;
  background-color: transparent;
  display: inline-block;
  line-height: 1;
  padding: 17px 30px 12px 30px;
  transition: background-color 150ms linear, color 150ms linear;
  cursor: pointer;
  outline: none;
  &:hover,
  &.react-tabs__tab--selected,
  &.fill {
    background-color: $color-purple;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}

//
form {
  .form_description {
    margin: 50px auto 30px;
  }
  .input_wrapper {
    position: relative;
    margin: 0 auto 30px;
    background-color: #fff;

    label {
      position: absolute;
      top: 6px;
      left: 5px;
      color: #9cadc1;
      line-height: 1;
      z-index: 1;
      transition: all 150ms linear;
      padding: 10px;
      margin: 0px;
    }

    &.fill {
      .form-control {
        // border-color: #fff;
        background-color: #fff;
      }
      label {
        top: -27px;
        left: -10px;
        font-size: 14px;
        color: $text-color;
        z-index: 9;
      }
    }
    &.pass {
      span {
        display: block;
        z-index: 2;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translate(-0%, -50%);
        right: 15px;
        height: 11px;
        width: 18px;
        //background-image: url('../images/ico_eye.svg');
        //background-position: center center;
        //background-size: contain;
        //background-repeat: no-repeat;
      }
    }
    //&.copy {
    //  max-width: 450px;
    //  margin: 0 0 30px 0;
    //  .btn_copy {
    //    cursor: pointer;
    //    position: absolute;
    //    border: none;
    //    box-shadow: none;
    //    top: 50%;
    //    transform: translate(-0%, -50%);
    //    right: -40px;
    //    width: 21px;
    //    height: 27px;
    //    background-image: url('../images/ico_copy.svg');
    //    background-position: center center;
    //    background-size: contain;
    //    background-repeat: no-repeat;
    //  }
    //}
  }
  .form-control {
    border: 1px solid $color-purple;
    background-color: transparent;
    height: auto;
    outline: none;
    box-shadow: none;
    border-radius: 0px;
    display: block;
    width: 100%;
    padding: 10px 15px;
    z-index: 2;
    position: relative;
    color: $text-color;
  }
  .form_info {
    font-size: 13px;
    margin: 0 auto 10px;
    padding: 0 0 0 25px;
    span,
    a {
      color: $color-purple;
    }
  }
  .link_add_email {
    display: block;
    margin: 0 auto 30px;
    font-weight: 600;
  }
  &.narrow {
    max-width: 500px;
  }
}

//
.select_label {
  font-size: 14px;
  font-weight: 400;
  color: $text-color;
}
.select_wrapper {
  position: relative;
  border: 1px solid $color-purple;
  background-color: #fff;
  select {
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: #9cadc1;
    padding: 10px 12px;
  }
  &:before {
    content: '';
    width: 13px;
    height: 9px;
    position: absolute;
    top: 50%;
    transform: translate(-0%, -50%);
    right: 15px;
    // background: url('../images/ico_arrow_select.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    z-index: 1;
  }
  &.disabled {
    pointer-events: none;
    border-color: #d8d8d8;
    &:before {
      opacity: 0.4;
    }
  }
  &.with_margin {
    margin: 0 auto 30px;
  }
}

//
.loading-indicator {
  margin: 2em auto;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: grey;
      margin: -3px 0 0 -3px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
      &:after {
        top: 50px;
        left: 50px;
      }
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
      &:after {
        top: 54px;
        left: 45px;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
      &:after {
        top: 57px;
        left: 39px;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
      &:after {
        top: 58px;
        left: 32px;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
      &:after {
        top: 57px;
        left: 25px;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
      &:after {
        top: 54px;
        left: 19px;
      }
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
      &:after {
        top: 50px;
        left: 14px;
      }
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
      &:after {
        top: 45px;
        left: 10px;
      }
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: transparent;
      transition: all 150ms linear;
      margin: 0 5px;
      &.current {
        background-color: $color-purple;
        color: #fff;
      }
      &:hover {
        background-color: $color-purple;
        color: #fff;
      }
    }
    &:first-child {
      a {
        left: 0;
        position: relative;
        &:hover {
          left: -5px;
          background-color: transparent;
          color: $text-color;
        }
      }
    }
    &:last-child {
      a {
        right: 0;
        position: relative;
        &:hover {
          right: -5px;
          background-color: transparent;
          color: $text-color;
        }
      }
    }
  }
  &.inline_header {
    margin: 0 auto 20px;
    position: relative;
    top: -5px;
  }
}

//
// .checkbox_wrapper {
//   display: block;
//   &.with_margin {
//     margin: 0 auto 10px;
//   }
// }

//
// <div className="radio_wrapper">
//   <input type="radio" name="basicR1" id="basicR1" />
//   <label htmlFor="basicR1">Dodatkowe godziny</label>
// </div>

.radio_wrapper {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.5;
  display: inline-block;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 13px;
  height: 13px;
  border: 1px solid $color-purple;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 7px;
  height: 7px;
  background: $color-purple;
  position: absolute;
  top: 8px;
  left: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
