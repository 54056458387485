@media only screen and (max-width: 1441px) {
}

@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (min-width: 767px) {
  .hamburger {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {
  html {
    min-width: 320px;
  }
}
