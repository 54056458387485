@import '~styles/variables';

.checkbox {
  &__error-field {
    font-size: 14px;
    color: red;
    min-height: 30px;
  }

  input[type='checkbox'] {
    height: 0;
    overflow: hidden;
    display: block;

    & + span {
      display: block;
      position: relative;
      padding-left: 30px;
      font-size: 16px;
      line-height: 1.5;
      margin: 0 auto 10px;
      cursor: pointer;
      user-select: none;

      a {
        font-weight: 700;
      }
    }
    & + span:before {
      transition: box-shadow 0.2s linear;
    }

    & + span:last-child {
      margin-bottom: 0;
    }

    &:focus {
      & + span:before {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 3px rgba($color-purple, 0.5);
      }
    }

    &:checked + span:before,
    &:not(:checked) + span:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 13px;
      height: 13px;
      border: 1px solid $color-purple;
      background: #fff;
    }

    &:checked + span:after,
    &:not(:checked) + span:after {
      content: '';
      width: 7px;
      height: 7px;
      background: $color-purple;
      position: absolute;
      top: 8px;
      left: 3px;
      transition: all 0.2s ease;
    }

    &:not(:checked) + span:after {
      opacity: 0;
      transform: scale(0);
    }
    &:checked + span:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
