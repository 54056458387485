@import "./variables";

.input {
  margin-top: $input-top-margin;
  position: relative;

  &--hide-label {
    margin-top: 0;
  }

  &__label {
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 5px;
    color: #9cadc1;
    line-height: 1;
    transition: all 150ms linear;
    padding: 10px;
    margin: 0;
    z-index: 2;
    pointer-events: none;
    max-width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label--without-animation,
  &--is-filled label,
  input:focus + label,
  input:active + label,
  input:-webkit-autofill + label,
  input:-webkit-autofill:active + label,
  input:-webkit-autofill:hover + label,
  input:-webkit-autofill:focus + label,
  textarea:focus + label,
  textarea:-webkit-autofill + label,
  textarea:-webkit-autofill:active + label,
  textarea:-webkit-autofill:hover + label,
  textarea:-webkit-autofill:focus + label,
  textarea:active + label {
    top: -27px;
    left: -10px;
    font-size: 14px;
    color: #4e4188;
    z-index: 9;
  }

  input:focus,
  input:active,
  textarea:focus,
  textarea:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 1px rgba($color-purple, 0.5);
  }

  &--disabled {
    opacity: 0.7;
  }

  &__input {
    border: 1px solid $color-purple;
    height: auto;
    outline: none;
    box-shadow: none;
    border-radius: 0px;
    display: block;
    width: 100%;
    padding: $input-vertical-padding $input-horizontal-padding;
    z-index: 2;
    position: relative;
    color: $text-color;
    background-color: #fff;

    &--disabled {
      pointer-events: none;
    }
  }

  &--pass input {
    padding-right: 45px;
  }

  &__error-field {
    font-size: 14px;
    color: red;
    //margin-bottom: 16px;
    min-height: 30px;
  }

  &--filled {
    margin-bottom: 16px;
    background-color: transparent;
  }

  .error {
    font-size: 14px;
    color: red;
  }

  &__input-icon {
    display: block;
    z-index: 2;
    position: absolute;
    top: $input-vertical-padding;
    right: $input-horizontal-padding;
    cursor: pointer;
    color: #a2b2c5;

    top: 50%;
    transform: translateY(-50%);
  }

  &__description {
    display: block;
    font-size: 12px;
  }
}
