.component-wrapper {
  box-shadow: 0 0 25px 0 rgba(#000, 0.1);
  margin: 10px;

  &__component-name {
    margin: 0;
    padding: 20px;
    text-align: center;
  }

  &__preview {
    padding: 20px;
  }
}
