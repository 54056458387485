.page_404 {
  position: absolute;
  top: 50%;
  transform: translate(-0%, -50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-size: 90px;
  }
  p {
    margin: 0 auto 30px;
    font-size: 24px;
  }
}
