@import '~styles/variables.scss';
@import '~styles/mixins.scss';

$container-mobile-padding: 20px;
$container-padding: 40px;

.technical-message {
  margin: 0 0 40px 0;
  box-shadow: 0 0 25px 0 rgba(#000, 0.1);
  padding: $container-mobile-padding;
  position: relative;
  display: flex;

  &__title {
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  &__icon-wrapper {
    width: 90px;
    margin-right: $container-mobile-padding;
    display: flex;
  }

  &__icon {
    margin: auto;
  }

  &__close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  &--dark {
    background-image: linear-gradient(to bottom right, #957efc, #ff6cab);
    color: white;

    a {
      color: white;
    }
  }

  @include mdScreen() {
    padding: $container-padding;

    &__icon-wrapper {
      margin-right: $container-padding;
    }
  }
}
