@import '~styles/variables';
@import '~styles/mixins';

.register-confirm {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;

  &__title {
    font-size: 2.5rem;
    margin: 0 auto 15px;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: -15px;
    overflow: hidden;
  }

  &__login-button,
  &__go-main-button {
    padding: 15px;
    width: 100%;
    flex: 1;

    button,
    a {
      width: 100%;
      text-align: center;
    }
  }

  &__description {
    margin: 0 auto 30px;
    font-size: 24px;
  }

  @include smScreen {
    &__title {
      font-size: 3rem;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;

      button,
      a {
        width: auto;
        white-space: nowrap;
      }
    }
  }

  @include lgScreen {
    &__title {
      font-size: 4rem;
    }
  }
}
