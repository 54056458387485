@import '../Button/button_mixins';

.link-button {
  @include button-basic();

  &--kind-simple {
    @include button-simple();
  }

  &--kind-outline {
    @include button-outline();
  }

  &--kind-link {
    @include button-link();
  }

  &--kind-standard {
    @include button-standard();
  }
}
