$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

$color-green: #95bf19;
$color-orange: #fdac00;
$color-red: #e74c3c;
$color-purple: #977fff;

$primary-light-color: $color-purple;
$primary-color: #4e4188;
$primary-dark-background-color: #4e4188;
$primary-text-color: #4e4188;
$text-color: #4e4188;
$color-purple: #977fff;

$layout-background-color: #fbfbfb;

$desktop-aside-menu-width: 315px;
$layout-content-vertical-padding-desktop: 40px;
$layout-content-horizontal-padding-desktop: 22px;
$layout-content-vertical-padding-mobile: 22px;
$layout-content-horizontal-padding-mobile: 10px;
